.Content-glow {
  font-size: calc(10px + 10vmin);
  color: #fff;
  text-align: center;
  animation: glow 1s ease-in-out infinite alternate;
  font-family: Akronim;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.Content-glow:hover {
  font-size: 145px;
  color: #fff;
  text-align: center;
  animation: glow2 1s ease-in-out infinite alternate;
  font-family: Akronim;
}


@keyframes glow {
  from {
    text-shadow: 0 0 10px #dd3300, 0 0 20px #dd3300, 0 0 30px #ff9900, 0 0 40px #ff9900, 0 0 50px #ff9900, 0 0 60px #ff9900, 0 0 70px #ff9900;
  }
  
  to {
    text-shadow: 0 0 20px #dd3300, 0 0 30px #ffbb00, 0 0 40px #ffbb00, 0 0 50px #ffbb00, 0 0 60px #ffbb00, 0 0 70px #ffbb00, 0 0 80px #ffbb00;
  }
}

@keyframes glow2 {
  from {
    text-shadow: 0 0 10px #000, 0 0 20px #000, 0 0 30px #0099ff, 0 0 40px #0099ff, 0 0 50px #0099ff, 0 0 60px #0099ff, 0 0 70px #0099ff;
  }
  
  to {
    text-shadow: 0 0 20px #dd3300, 0 0 30px #00bbff, 0 0 40px #00bbff, 0 0 50px #00bbff, 0 0 60px #00bbff, 0 0 70px #00bbff, 0 0 80px #00bbff;
  }
}
