.Footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #333333;
    color: #993300;
    font-family: Hack;
    text-align: center;
}

.Footer > span {
    margin-left: 1%;
}



